@font-face {
	font-family: 'GothamCondensed';
	font-style: normal;
	font-stretch: normal;
	font-weight: 400;
	font-feature-settings: normal;
	src: url(../fonts/GothamCondensed-Book.otf);
}

@font-face {
	font-family: 'GothamCondensed';
	font-style: normal;
	font-stretch: normal;
	font-weight: 600;
	font-feature-settings: normal;
	src: url(../fonts/GothamCondensed-Bold.otf);
}

@font-face {
	font-family: 'Subtitle';
	font-style: normal;
	font-stretch: normal;
	font-weight: 400;
	font-feature-settings: normal;
	src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face {
	font-family: 'Info';
	font-style: normal;
	font-stretch: normal;
	font-weight: 400;
	font-feature-settings: normal;
	src: url(../fonts/OpenSans-Regular.ttf);
}
